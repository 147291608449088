import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { Layout, Seo } from '../components';
import { Faq } from '../utils';
import styles from './faq.module.css';

interface QueryResult {
  strapi: {
    faq: Faq,
  },
};
export const query = graphql`
  query {
    strapi {
      faq {
        title
        categories {
          id
          name
          questions {
            id
            question
            answer
          }
        }
      }
    }
  }
`;

const FaqPage = ({ data }: { data: QueryResult }) => {
  const page = data.strapi.faq;

  return (
    <Layout>
      <Seo title={page.title} />
      <section className={styles.faq}>
        <div className="container">
          <h2>{page.title}</h2>
          <Row gutter={32}>
            {page.categories.map(category => (
              <Col key={category.id} md={12}>
                <h3>{category.name} ({category.questions.length})</h3>
                <Collapse
                  ghost
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                >
                  {category.questions.map(question => (
                    <Collapse.Panel key={question.id} header={question.question}>
                      <ReactMarkdown source={question.answer} />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </Layout>
  );
};

export default FaqPage;
